<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                发货人：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.sendUser" size="small" placeholder="请输入发货人"
                                ></el-input>
                            </td>
                            <td style="width: 80px;">
                                公司名称：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px;">
                                发货地址：
                            </td>
                            <td>
                                <el-input v-model="searchItem.receivingAddress" style="width: 100%;" size="small"
                                          placeholder="请输入发货地址"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px;">
                                发货通知单：
                            </td>
                            <td class="td-input" style=" padding-top: 10px;width: 28%">
                                <el-input v-model="searchItem.sendNoticeNum" size="small" placeholder="请输入发货通知单"
                                ></el-input>
                            </td>
                            <td class="td-text" style="padding-top: 10px;">
                                发货单编号：
                            </td>
                            <td class="td-input" style=" padding-top: 10px">
                                <el-input v-model="searchItem.outListNum" size="small" placeholder="请输入发货单编号"
                                ></el-input>
                            </td>
                            <td class="td-text" style="padding-top: 10px;">
                                发货时间：
                            </td>
                            <td class="td-input" style=" padding-top: 10px;width: 28%">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47%"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                合同/协议编号：
                            </td>
                            <td style="padding-top: 10px">
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入合同/协议编号"
                                ></el-input>
                            </td>
                            <td>
                                IMEI号：
                            </td>
                            <td>
                                <el-input v-model="searchItem.imeiNum" size="small" placeholder="请输入IMEI号"
                                ></el-input>
                            </td>
                            <td colspan="2" style="padding-top: 10px;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh-right" style="margin-left: 5%"
                                           size="small" @click="reset">重置
                                </el-button>
                                <!--<el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出为Excel文件</el-button>-->
                                <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                           style="margin-left: 5%" v-if="hasAuthority('out_depot_add')"
                                           @click="addDepot">增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" align="center"></el-table-column>
                            <el-table-column prop="contractNum" label="合同/协议编号" width="170" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="sendNoticeNum" label="发货通知单" width="150" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="outListNum" label="发货单编号" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyName" label="公司名称"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyType" label="公司类型" width="140" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-else-if="scope.row.companyType == 1">国企</span>
                                    <span v-else-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-else-if="scope.row.companyType == 3">代理商</span>
                                    <span v-else>集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="发货时间" width="110" :formatter="changeStyle"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="receivingAddress" label="发货地址" show-overflow-tooltip
                                             align="center"></el-table-column>
                            <el-table-column prop="sendCount" label="发货数量" width="80" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="surplusCount" label="剩余数量" width="80" align="center"
                                             show-overflow-tooltips></el-table-column>
                            <el-table-column prop="sendUser" label="发货人" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column label="上传清单" width="120" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-button size="small" type="text" style="cursor: pointer" disabled
                                               @click="uploadFiles(scope.row.id)">上传附件
                                    </el-button>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="100" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑发货单"
                                           v-if="hasAuthority('out_depot_update')"
                                           @click="editOutDepot(scope.row.id,scope.row.contractId)"></i>
                                        <i class="iconfont iconxiangqing" title="查看详情"
                                           @click="showOutDepot(scope.row.id,scope.row.contractId)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="upload-dialog">
            <Upload @openDialog="openDialog" :fileId="depotId" :fileType="uploadType" :dialogVisibles="dialogVisibles"/>
        </div>
        <div class="dialog-edit">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="dialogVisible"
                    width="70%"
                    :close-on-click-modal="isClose"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-body" ref="middle">
                    <el-form :model="depotForm" :rules="rules" ref="depotForm" label-width="100px"
                             class="demo-ruleForm">
                        <el-row>
                            <el-col style="width: 50%">
                                <el-form-item label="发货通知单" prop="sendNoticeNum">
                                    <el-select @change="getFillShipping" size="small" :disabled="isDisabled"
                                               v-model="depotForm.contractId"
                                               filterable style="width: 100%">
                                        <el-option
                                                v-for="item in shippingNums"
                                                :key="item.id"
                                                :label="item.sendNoticeNum"
                                                :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col style="width: 50%">
                                <el-form-item size="small" label="合同/协议编号" prop="number">
                                    <el-input size="small" disabled v-model="depotForm.contractNum" readonly></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col style="width: 50%">
                                <el-form-item size="small" label="公司名称" prop="companyName">
                                    <el-input size="small" disabled v-model="depotForm.companyName" readonly></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col style="width: 50%">
                                <el-form-item size="small" label="项目名称" prop="projectName">
                                    <el-input size="small" disabled v-model="depotForm.projectName" readonly></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col style="width: 50%">
                                <el-form-item size="small" label="收货人" prop="receivingUser">
                                    <el-input size="small" v-model="depotForm.receivingUser"
                                              :disabled="isDisabled"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col style="width: 50%">
                                <el-form-item size="small" label="收货人电话" prop="receivingUserTel">
                                    <el-input size="small" v-model="depotForm.receivingUserTel"
                                              :disabled="isDisabled"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col style="width: 50%">
                                <el-form-item size="small" label="发货人" prop="sendUser">
                                    <el-input size="small" disabled v-model="depotForm.sendUser" readonly></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col style="width: 50%">
                                <el-form-item size="small" label="发货数量" prop="sendCount">
                                    <el-input size="small" disabled v-model.number="depotForm.sendCount"
                                              readonly></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="收货人地址" size="small" prop="receivingAddress">
                            <el-input size="small" v-model="depotForm.receivingAddress"
                                      :disabled="isDisabled"></el-input>
                        </el-form-item>

                        <el-form-item label="备注" size="small" prop="remark">
                            <el-input size="small" type="textarea" rows="3"
                                      v-model="depotForm.remark"></el-input>
                        </el-form-item>
                    </el-form>
                    <template>
                        <el-table
                                :data="basicTable"
                                style="width: 100%"
                                border
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: '41px'}">
                            <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                            <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                    <span v-else>塔机</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="sizeModel" label="规格型号" align="center"></el-table-column>
                            <el-table-column prop="siteNum" label="现场编号" align="center"></el-table-column>
                            <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)" align="center"></el-table-column>
                            <el-table-column prop="armLength" label="前臂长度(m)" align="center"></el-table-column>
							<el-table-column prop="rearArmLength" label="后臂长度(m)" align="center"></el-table-column>
                            <el-table-column prop="maxLoad" label="最大载重(T)" align="center"></el-table-column>
                            <el-table-column prop="deviceType" label="类型" align="center"></el-table-column>
                            <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.isNeedCard == 1">是</span>
                                    <span v-else>否</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.isNeedModel == 1">是</span>
                                    <span v-else>否</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                    <div class="addNum">
                        <div class="input-style">
                            <el-table
                                    :data="editList"
                                    border
                                    style="width: 100%;margin-bottom: 10px"
                                    ref=""
                                    :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                    :cell-style="{padding:'0',height: lineHeight}">
                                <el-table-column type="index" label="排序" align="center" width="50"></el-table-column>
                                <el-table-column prop="deviceNum" label="现场编号" align="center">
                                    <template slot-scope="scope">
                                        <el-select size="small" v-model="scope.row.deviceNum"
                                                   @change="getChangeValue"
                                                   @focus="getValues(scope.row.deviceNum)"
                                                   :disabled="isDisabled"
                                                   placeholder="请选择现场编号">
                                            <el-option
                                                    v-for="(item,index) in deviceNums"
                                                    :key="index"
                                                    :label="item.siteNum"
                                                    :value="item.siteNum">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="imeiNum" label="IMEI号" align="center" width="180">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.imeiNum" placeholder="请输入IMEI号"
                                                  :disabled="isDisabled"
                                                  size="small"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="simNum" label="基础网卡号" align="center" width="210">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.simNum" placeholder="请输入基础网卡号"
                                                  size="small"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="videoCard" label="视频网卡号" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.videoCard" placeholder="请输入视频网卡号"
                                                  style="width: 100%"
                                                  size="small"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="inCount" label="驾驶室视频" align="center">
                                    <template slot-scope="scope">
                                        <el-input size="small"
                                                  v-model="scope.row.cockpitVideo"
                                                  placeholder="请输入驾驶室视频"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="inCount" label="盲吊视频" align="center">
                                    <template slot-scope="scope">
                                        <el-input size="small"
                                                  v-model="scope.row.blindVideo"
                                                  placeholder="请输入盲吊视频"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="inCount" label="其他视频" align="center">
                                    <template slot-scope="scope">
                                        <el-input size="small"
                                                  v-model="scope.row.otherVideo"
                                                  placeholder="请输入其他视频"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="120" align="center" v-if="!isDisabled">
                                    <template slot-scope="scope">
                                        <el-button type="danger" icon="el-icon-delete" size="mini"
                                                   @click="deleteBox(scope.$index)">删除物料
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="button" v-show="!isDisabled">
                            <el-button type="success" icon="el-icon-ok" size="small"
                                       @click="addBox">新增设备
                            </el-button>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('depotForm')">取 消</el-button>
                    <el-button type="primary" @click="saveOutDepot('depotForm')" :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="dialog-info">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div>
                            <table style="text-align: left;width: 100%">
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        公司名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.companyName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同号：</td>
                                    <td style="width: 170px">
                                        {{outDepot.contractNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">客户类型：</td>
                                    <td style="width: 300px">
                                        {{outDepot.companyType==0?"建机厂":(outDepot.companyType==1?"国企":(outDepot.companyType==2?"租赁商":(outDepot.companyType==3?"代理商":"集团公司")))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        通知单时间：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.sendNoticeTime}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">塔机数量：</td>
                                    <td style="width: 170px">
                                        {{outDepot.towerCount}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">升降机数量：</td>
                                    <td style="width: 170px">
                                        {{outDepot.lifterCount}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        收货人：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.receivingUser}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">联系电话：</td>
                                    <td style="width: 170px">
                                        {{outDepot.receivingUserTel}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">送货地址：</td>
                                    <td style="width: 170px">
                                        {{outDepot.receivingAddress}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        项目名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.projectName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        服务周期(月)：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.startDate}}~{{outDepot.endDate}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        设备类型
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.deviceType==0?'普通型':'加强型'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px;">
                                        备注：
                                    </td>
                                    <td style="width: 300px;text-align: left" colspan="5">
                                        {{outDepot.remark}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 92px">功能需求：</td>
                                    <td colspan="5">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        序号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.index}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                        塔机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        升降机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        特殊型号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <template>
                                <el-table
                                        :data="basicTable"
                                        border
                                        style="width: 100%;margin-top: 10px"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: '41px'}">
                                    <el-table-column type="index" label="序号" width="50px"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                            <span v-else>塔机</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sizeModel" label="规格型号" align="center"></el-table-column>
                                    <el-table-column prop="siteNum" label="现场编号" align="center"></el-table-column>
                                    <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="armLength" label="前臂长度(m)" align="center"></el-table-column>
									<el-table-column prop="rearArmLength" label="后臂长度(m)" align="center"></el-table-column>
                                    <el-table-column prop="maxLoad" label="最大载重(T)" align="center"></el-table-column>
                                    <el-table-column prop="deviceType" label="类型" align="center"></el-table-column>
                                    <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedCard == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedModel == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                            <el-table
                                    :data="numList"
                                    border
                                    :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                    :cell-style="{padding:'0',height: '41px'}"
                                    style="width: 100%;margin-top: 10px;margin-bottom:10px">
                                <el-table-column type="index" label="排序" align="center" width="50"
                                                 ></el-table-column>
                                <el-table-column prop="deviceNum" label="现场编号" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="imeiNum" label="IMEI号" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="simNum" label="基础网卡号1" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="videoCard" label="视频网卡号" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="cockpitVideo" label="驾驶室视频" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="blindVideo" label="盲吊视频" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="otherVideo" label="其他视频" align="center"
                                                 show-overflow-tooltip></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="infoVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                type: 0,
                pageList: [14, 20, 30, 50],
                tableHeight: '',
                showTitle: '',
                towerItems: [],
                deviceNums: [],
                deviceNumsStr: '',
                lifterItems: [],
                specialItems: [],
                editList: [
                    {
                        deviceNum: '',
                        imeiNum: '',
                        SIMN: '',
                        videoCard: '',
                        cockpitVideo: '',
                        blindVideo: '',
                        otherVideo: '',
                    }
                ],
                infoVisible: false,
                outDepot: {
                    receivingAddress: "",
                    receivingUser: "",
                    receivingUserTel: "",
                    chargePolicy: "",
                    companyName: "",
                    companyType: "",
                    contractName: "",
                    contractNum: "",
                    startDate: "",
                    endDate: "",
                    deviceType: "",
                    lifterCount: "",
                    projectName: "",
                    sendNoticeTime: "",
                    sendNoticeNum: "",
                    sendTotalCount: "",
                    specialPolicy: "",
                    towerCount: "",
                    userName: "",
                    remark: ""
                },
                isLoad: false,
                isDisabled: false,
                depotId: '',
                uploadType: '',
                basicTable: [],
                dialogVisible: false,
                dialogVisibles: false,
                multipleSelection: [],
                delList: [],
                // totalPage:0,
                totalPage: 30,
                option: {
                    pageIndex: '',
                    pageSize: 14,
                    currentPage: 2
                },
                searchItem: {
                    sendUser: '',
                    companyName: '',
                    receivingAddress: '',
                    startDate: '',
                    endDate: '',
                    outListNum: '',
                    contractNum: '',
                    sendNoticeNum: '',
                    imeiNum: '',
                    pageSize: 14,
                    pageIndex: 1
                },
                depotForm: {
                    id: null,
                    outListNum: '',
                    sendNoticeNum: '',
                    sendUser: '',
                    receivingAddress: '',
                    receivingUser: '',
                    receivingUserTel: '',
                    sendCount: 1,
                    contractId: '',
                    projectName: '',
                    createTime: '',
                    contractNum: '',
                    companyName: '',
                    deviceListStr: '',
                    remark: ''
                },
                isClose: false,
                rules: {
                    sendNoticeNum: [
                        {required: true, message: '请选择发货申请编号', trigger: 'blur'}
                    ],
                },
                tableData: [],
                shippingNums: [],
                numList: [],
                lineHeight: '',
                editTitle: '',
            }
        },
        methods: {
            getFillShipping(val) {
                this.deviceNums = [];
                this.$api.getFillData({contractId: val}).then(res => {
                    if (res.code == 200) {
                        if (res.data.deviceList == []) {
                            this.depotForm = {
                                outListNum: '',
                                contractId: res.data.id,
                                sendUser: localStorage.getItem("username"),
                                sendNoticeNum: res.data.sendNoticeNum,
                                sendCount: 1,
                                projectName: res.data.projectName,
                                contractNum: res.data.contractNum,
                                companyName: res.data.companyName,
                                deviceListStr: ''
                            }
                        } else {
                            this.depotForm = {
                                outListNum: '',
                                contractId: res.data.id,
                                sendUser: localStorage.getItem("username"),
                                sendNoticeNum: res.data.sendNoticeNum,
                                receivingAddress: res.data.deviceList[0].receivingAddress,
                                receivingUser: res.data.deviceList[0].receivingUser,
                                receivingUserTel: res.data.deviceList[0].receivingUserTel,
                                sendCount: 1,
                                projectName: res.data.projectName,
                                contractNum: res.data.contractNum,
                                companyName: res.data.companyName,
                                deviceListStr: ''
                            }
                        }
                        for (var item of res.data.deviceList) {
                            var deviceItem = {}
                            deviceItem.siteNum = item.siteNum
                            this.deviceNums.push(item);
                        }
                        this.deviceNumsStr = JSON.stringify(this.deviceNums)
                        this.basicTable = res.data.deviceList;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageDepotOut(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageDepotOut(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            uploadFiles(id) {
                this.dialogVisibles = true;
                this.depotId = id;
                this.uploadType = 'outDepot'
            },
            openDialog(val) {
                this.dialogVisibles = !this.dialogVisibles;
            },
            addDepot() {
                this.basicTable = []
                this.deviceNums = [];
                this.editList = [{}];
                this.isLoad = false
                this.type = 0
                this.isDisabled = false
                this.editTitle = "新增发货信息"
                var sender = localStorage.getItem("username")
                this.dialogVisible = true;
                this.depotForm = {
                    id: null,
                    outListNum: '',
                    contractId: '',
                    sendNoticeNum: '',
                    sendUser: sender,
                    receivingAddress: '',
                    receivingUser: '',
                    receivingUserTel: '',
                    sendCount: 1,
                    createTime: '',
                    contractNum: '',
                    companyName: '',
                    projectName: '',
                    deviceListStr: '',
                    remark: '',
                }
                this.editList = [{
                    imeiNum: '',
                    simNum: '',
                    deviceNum: '',
                    videoCard: '',
                    cockpitVideo: '',
                    blindVideo: '',
                    otherVideo: '',
                }]
                this.$api.getShippingList().then(res => {
                    if (res.code == 200) {
                        this.shippingNums = res.data
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            showOutDepot(id, contractId) {
                this.infoVisible = true;
                this.towerItems = [];
                this.lifterItems = [];
                this.$api.getOutdepotDetail({id: id, contractId: contractId}).then(res => {
                    if (res.code == 200) {
                        this.outDepot = {
                            receivingAddress: res.data.warehouseOutList.receivingAddress,
                            receivingUser: res.data.warehouseOutList.receivingUser,
                            receivingUserTel: res.data.warehouseOutList.receivingUserTel,
                            chargePolicy: res.data.chargePolicy,
                            companyName: res.data.companyName,
                            companyType: res.data.companyType,
                            contractName: res.data.contractName,
                            contractNum: res.data.contractNum,
                            startDate: res.data.startDate,
                            endDate: res.data.endDate,
                            deviceType: res.data.deviceType,
                            lifterCount: res.data.lifterCount,
                            projectName: res.data.projectName,
                            sendNoticeTime: res.data.sendNoticeTime,
                            sendNoticeNum: res.data.sendNoticeNum,
                            sendTotalCount: res.data.sendTotalCount,
                            specialPolicy: res.data.specialPolicy,
                            towerCount: res.data.towerCount,
                            userName: res.data.userName,
                            remark: res.data.warehouseOutList.remark
                        };
                        this.contractFile = {
                            id: res.data.id,
                            projectMsgUrl: res.data.projectMsgUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            deviceListUrl: res.data.deviceListUrl,
                        }
                        this.isPass = res.data.auditState;
                        this.basicTable = res.data.deviceList;
                        this.showTitle = '《' + res.data.contractNum + '》详情信息'
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = specialItemsStr[i];
                                this.specialItems.push(funcItem);
                            }
                        }
                        this.numList = res.data.warehouseDeviceList;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            getChangeValue(val) {
                var newArr = JSON.parse(this.deviceNumsStr);
                let filter = []
                for (var item of this.editList) {
                    filter = newArr.filter(
                        (itemObj) => itemObj.siteNum != item.deviceNum
                    );
                    newArr = filter;
                }
                this.deviceNums = filter;
            },
            getValues(val) {
                if (val) {
                    var newArr = JSON.parse(this.deviceNumsStr);
                    let filter = []
                    for (var item of this.editList) {
                        filter = newArr.filter(
                            (itemObj) => itemObj.siteNum != item.deviceNum
                        );
                        newArr = filter;
                    }
                    this.deviceNums = filter;
                }
            },
            editOutDepot(id, contractId) {
                this.$api.getShippingList().then(res => {
                    if (res.code == 200) {
                        this.shippingNums = res.data
                    } else {
                        this.$message.error(res.message)
                    }
                })
                this.dialogVisible = true;
                this.isDisabled = true;
                this.type = 1
                this.towerItems = [];
                this.lifterItems = [];
                this.$api.getOutdepotDetail({id: id, contractId: contractId}).then(res => {
                    if (res.code == 200) {
                        this.editTitle = '编辑《' + res.data.warehouseOutList.outListNum + '》发货单'
                        this.depotForm = {
                            id: res.data.warehouseOutList.id,
                            outListNum: res.data.outListNum,
                            sendNoticeNum: res.data.sendNoticeNum,
                            sendUser: res.data.warehouseOutList.sendUser,
                            receivingAddress: res.data.warehouseOutList.receivingAddress,
                            receivingUser: res.data.warehouseOutList.receivingUser,
                            receivingUserTel: res.data.warehouseOutList.receivingUserTel,
                            sendCount: res.data.warehouseOutList.sendCount,
                            contractId: contractId,
                            projectName: res.data.projectName,
                            createTime: res.data.createTime,
                            contractNum: res.data.contractNum,
                            companyName: res.data.companyName,
                            deviceListStr: res.data.deviceListStr,
                            remark: res.data.warehouseOutList.remark
                        };
                        this.basicTable = res.data.deviceList;
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = specialItemsStr[i];
                                this.specialItems.push(funcItem);
                            }
                        }
                        this.editList = res.data.warehouseDeviceList;
                    }
                })
            },
            searchBtn() {
                this.pageChange(1)
            },
            cancel(formName) {
                this.dialogVisible = false
                this.$refs[formName].resetFields();
                this.editList = [{
                    imeiNum: '',
                    simNum: '',
                    deviceNum: '',
                    videoCard: '',
                    cockpitVideo: '',
                    blindVideo: '',
                    otherVideo: '',
                }]
            },

            saveOutDepot(formName) {
                this.isLoad = true
                for (var item of this.editList) {
                    if (item.deviceNum == '' || item.deviceNum == null || item.deviceNum == undefined) {
                        this.$message.error('现场编号不能为空，请仔细检查');
                        this.isLoad = false
                        return;
                    } else {
                        if (item.imeiNum == '' || item.imeiNum == null || item.imeiNum == undefined) {
                            this.$message.error('请输入现场编号为' + item.deviceNum + '的IMEI号');
                            this.isLoad = false
                            return;
                        }
                        if (item.simNum == '' || item.simNum == null || item.simNum == undefined) {
                            this.$message.error('请输入现场编号为' + item.deviceNum + '的基础网卡号');
                            this.isLoad = false
                            return;
                        }
                    }
                }
                for (var i = 0; i < this.editList.length; i++) {
                    for (var j = i + 1; j < this.editList.length; j++) {
                        if (this.editList[i].deviceNum != '' && this.editList[j].deviceNum != '') {
                            if (this.editList[i].deviceNum == this.editList[j].deviceNum) {
                                this.$message.error('设备第' + (i + 1) + '行和第' + (j + 1) + '行现场编号重复，请仔细检查');
                                this.isLoad = false
                                return;
                            }
                        }
                        if (this.editList[i].imeiNum != '' && this.editList[j].imeiNum != '') {
                            if (this.editList[i].imeiNum == this.editList[j].imeiNum) {
                                this.$message.error('设备第' + (i + 1) + '行和第' + (j + 1) + '行IMEI号重复，请仔细检查');
                                this.isLoad = false
                                return;
                            }
                        }
                        if (this.editList[i].simNum != '' && this.editList[j].simNum != '') {
                            if (this.editList[i].simNum == this.editList[j].simNum) {
                                this.$message.error('设备第' + (i + 1) + '行和第' + (j + 1) + '行基础网卡号重复，请仔细检查');
                                this.isLoad = false
                                return;
                            }
                        }
                        if (this.editList[i].videoCard != '' && this.editList[j].videoCard != ''
                            && this.editList[i].videoCard != undefined && this.editList[j].videoCard != undefined
                            && this.editList[i].videoCard != null && this.editList[j].videoCard != null) {
                            if (this.editList[i].videoCard == this.editList[j].videoCard) {
                                this.$message.error('设备第' + (i + 1) + '行和第' + (j + 1) + '行视频网卡号重复，请仔细检查');
                                this.isLoad = false
                                return;
                            }
                        }
                    }
                }
                for (var item of this.editList) {
                    for (var deviceItem of this.deviceNums) {
                        if (item.deviceNum == deviceItem.siteNum
                            && deviceItem.isNeedCard == 1
                            && (!item.videoCard || !item.videoCard.trim())) {
                            this.$message.error('请输入现场编号为' + item.deviceNum + '的视频网卡号');
                            this.isLoad = false
                            return;
                        }
                    }
                }
                var listStr = ''
                for (var item of this.editList) {
                    if (item.videoCard == '' || item.videoCard == undefined || item.videoCard == null) {
                        item.videoCard = '';
                    }
                }
                for (var item of this.editList) {
                    if (listStr == '') {
                        listStr = item.simNum + ','
                            + item.imeiNum + ','
                            + item.deviceNum + ','
                            + item.videoCard + ','
                            + item.cockpitVideo + ','
                            + item.blindVideo + ','
                            + item.otherVideo
                    } else {
                        listStr = listStr + '|' + item.simNum + ','
                            + item.imeiNum + ','
                            + item.deviceNum + ','
                            + item.videoCard + ','
                            + item.cockpitVideo + ','
                            + item.blindVideo + ','
                            + item.otherVideo + ''
                    }
                }
                this.depotForm.deviceListStr = listStr;
                if (this.type == 0) {
                    this.$api.addDepotOut(this.depotForm).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.dialogVisible = false;
                            this.$message.success(res.message)
                            this.$refs[formName].resetFields();
                            this.isLoad = false
                        } else {
                            this.$message.error(res.message)
                            this.isLoad = false
                        }
                    })
                } else {
                    this.$api.updateDepotOut(this.depotForm).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.dialogVisible = false;
                            this.$message.success(res.message)
                            this.isLoad = false
                            this.$refs[formName].resetFields();
                        } else {
                            this.$message.error(res.message)
                            this.isLoad = false
                        }
                    })
                }
            },
            addBox() {
                this.editList.push({
                    deviceNum: '',
                    imeiNum: '',
                    SIMN: '',
                    videoCard: '',
                    cockpitVideo: '',
                    blindVideo: '',
                    otherVideo: '',
                })
                this.depotForm.sendCount = this.depotForm.sendCount + 1
                this.toBottom()
            },
            deleteBox(index) {
                this.editList.splice(index, 1)
                this.depotForm.sendCount = this.depotForm.sendCount - 1
            },
            reset() {
                this.searchItem = {
                    contractNum: '',
                    companyName: '',
                    sendUser: '',
                    imeiNum: '',
                    receivingAddress: '',
                    startDate: '',
                    endDate: '',
                    outListNum: '',
                    pageSize: 14,
                    pageIndex: 1,
                }
                this.pageChange(1);
            },
            changeStyle(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date

            },
            toBottom() {
                this.$nextTick(() => {
                    debugger;
                    let middle = this.$refs["middle"];
                    middle.scrollTop = middle.scrollHeight;
                })
            }
        },
        created() {
            this.searchBtn();
            this.tableHeight = (window.innerHeight) * 0.66
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }

    }
</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
        margin-right: 20px;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .addNum {
        margin-top: 10px;
        width: 100%;
    }

    .input {
        display: flex;
        width: 100%;
    }

    .button {

        margin: 10px 0 0 0;
        text-align: center;
    }

    .input-style {
        display: flex;
        width: 100%;
        margin-top: 10px;
    }

    .text-show {
        padding: 0.5% 1%;
    }

    .button-style {
        padding: 0 1%;
    }

    .input-text {
        width: 180px;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 140px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .td-body {
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: scroll;
        width: 1180px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .handle-box{
        text-align: right;
    }

    .td-input {
        width: 28%;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: auto;
    }
</style>
